import React from "react"
import {Products } from "../utils/product"
import ComboTemplate from "../components/Templates/ComboTemplate"

const ComboFlynow = () => {
  const data = {
    id: "combo_app_ebooks",
    title: "3 EBOOKS + PREMIUM VITALÍCIO",
    discontPercentage: 25,
    URL_CHECKOUT: "https://buy.stripe.com/28og0L6qd19HbRuaET?locale=pt-BR&prefilled_promo_code=FLYN25",
    cta: "ADQUIRIR COMBO PROMOCIONAL",
    sections: [
      {
        section: "APLICATIVO (PLANO PREMIUM VITALÍCIO)",
        data: [Products.apps[0]]
      },
      {
        section: "EBOOKS",
        data: Products.ebooks
      },
    ],
  }
  return <ComboTemplate  {...data} />
}
export default ComboFlynow